<template>
  <div class="container home-news">
    <div class="news-header d-flex align-center justify-space-between">
      <h2 class="news-title">{{ $t('adsPage') }}</h2>
      <div class="news-all">
        <router-link to="/all-news">{{ $t('otherAds') }}</router-link>
      </div>
    </div>
    <vueper-slides
      :visible-slides="3"
      slide-multiple
      :gap="5"
      class="no-shadow news-slider"
      :bullets="false"
      :touchable="false"
    >
      <template #arrow-left>
        <div class="arrow arrow-left">
          <span class="icon icon-arrow"></span>
        </div>
      </template>

      <template #arrow-right>
        <div class="arrow arrow-right">
          <span class="icon icon-arrow"></span>
        </div>
      </template>
      <vueper-slide
        v-for="(item, i) in slides"
        :key="i"
        class="news-slider-wrapper"
      >
          <template #content>
            <div class="news-slider__item">
              <div class="news-time d-flex align-center">
                <span class="icon icon-time__before"></span>
                <span class="news-time__text">
                  {{ item.time }}
                </span>
              </div>
              <p class="news-content">
                {{ item.title }}
              </p>
              <div class="news-more">
                <router-link :to="'/news-more/' + item.id">
                  {{ $t('moreDetails') }}
                </router-link>
              </div>
            </div>
          </template>
        </vueper-slide>
    </vueper-slides>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';
import Api from '@/api/Api';

export default {
  name: 'HomeNews',
  components: {
    VueperSlides,
    VueperSlide,
  },
  data() {
    return {
      slides: [{
        content: 'string',
        time: '01 April 2022',
      }],
    };
  },
  methods: {
    async getNewsData() {
      const { data } = await Api.getNewsData({ size: 9 });
      this.slides = data.results.map((item) => {
        const {
          created_at: time,
          title,
          id,
        } = item;
        return {
          time: this.dateFormating(time),
          title,
          id,
        };
      });
    },
    dateFormating(date) {
      const currentDate = new Date(date);
      const dd = String(currentDate.getDate()).padStart(2, '0');
      const mm = String(currentDate.getMonth()+1).padStart(2, '0');
      const yyyy = currentDate.getFullYear();
      return `${dd}.${mm}.${yyyy}`;
    },
  },
  mounted() {
    this.getNewsData();
  },
};
</script>

<style lang="scss" src='./HomeNews.scss'>
</style>
