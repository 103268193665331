<template>
  <div class="container home-faq">
    <div class="faq-header d-flex align-center justify-space-between">
      <h2 class="faq-title">{{ $t('frequentlyQuestions') }}</h2>
      <div class="faq-all">
        <router-link to="/faqs">{{ $t('allQuestions') }}</router-link>
      </div>
    </div>
    <div
      class="faq-wrapper"
      v-for="item in faqList"
      :key="item.id"
      @click="changeActive(item)"
      :class="{ active: item.active }"
    >
      <div class="d-flex">
        <h2 class="faq__title">
          {{ item.title }}
        </h2>
        <div class="arrow-wrapper">
          <span class="icon icon-faqArrow"></span>
        </div>
      </div>
      <p class="faq__text">
        {{ item.text }}
      </p>
    </div>
  </div>
</template>

<script>
import Api from '@/api/Api';

export default {
  name: 'HomeFaq',
  data() {
    return {
      faqList: [],
    };
  },
  methods: {
    changeActive(item) {
      // eslint-disable-next-line no-param-reassign
      item.active = !item.active;
    },
    async GetFaqData() {
      const { data } = await Api.getFaqData({ size: 3 });
      this.faqList = data.results.map((item) => {
        const {
          answer: text,
          id,
          question: title,
        } = item;
        return {
          text,
          title,
          id,
          active: false,
        };
      });
      this.faqList[0].active = true;
    },
  },
  mounted() {
    this.GetFaqData();
  },
};
</script>

<style lang="scss" src="./HomeFaq.scss"></style>
