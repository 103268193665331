<template>
  <div class="container useful-links">
    <h2 class="useful-title">{{ $t('usefulLinks') }}</h2>
      <vueper-slides
      :visible-slides="3"
      slide-multiple
      :gap="2"
      class="no-shadow userful-slider"
      :bullets="true"
      :arrows="false"
      :touchable="false"
      :autoplay="true"
      :infinite="true"
    >
      <vueper-slide
        v-for="(slide, i) in links"
        :key="i"
      >
          <template #content>
            <a :href="slide.url" target="_blank" class="userful-slider__item d-flex">
              <div class="item-left">
                <div class="item-img__wrapper">
                  <img :src="slide.img" :alt="slide.url">
                </div>
              </div>
              <div class="item-right mt-5">
                <p class="mt-4">
                  {{ slide.content }}
                </p>
              </div>
              <a :href="slide.url" target="_blank" class="foreing-link">
                <span class="icon icon-foreign"></span>
              </a>
            </a>
          </template>
        </vueper-slide>
    </vueper-slides>
    </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';
import Api from '@/api/Api';

export default {
  name: 'UsefulLinks',
  components: {
    VueperSlides,
    VueperSlide,
  },
  data() {
    return {
      links: [],
    };
  },
  methods: {
    async getUsefulLinksData() {
      const { data } = await Api.getUsefulLinksData({ is_active: true });
      this.links = data.results.map((item) => {
        const {
          id,
          icon: img,
          name: content,
          url,
        } = item;
        return {
          id,
          img,
          content,
          url,
        };
      });
      this.links.splice(9, 9999);
    },
  },
  mounted() {
    this.getUsefulLinksData();
  },
};
</script>

<style lang="scss" src="./UsefulLinks.scss"></style>
