<template>
  <div class="home">
    <!-- <warring-test-component/> -->
    <public-header/>
    <particles />
    <main>
      <home-search />
      <home-SDMX />
      <home-news />
      <useful-links />
      <home-faq/>
    </main>
    <home-footer />
  </div>
</template>

<script>
// import WarringTestComponent from '@/components/WarringTestComponent';
import PublicHeader from '@/components/PublicHeader';
import HomeNews from '@/components/HomeNews';
import UsefulLinks from '@/components/UsefulLinks';
import HomeFaq from '@/components/HomeFaq';
import HomeFooter from '@/components/HomeFooter';
import HomeSearch from '@/components/HomeSearch';
import HomeSDMX from '@/components/HomeSDMX';
import particles from './Particles.vue';

export default {
  name: 'HomePage',
  components: {
    // WarringTestComponent,
    PublicHeader,
    HomeSearch,
    HomeSDMX,
    HomeNews,
    UsefulLinks,
    HomeFaq,
    HomeFooter,
    particles,
  },
};
</script>

<style lang="scss" src="./HomePage.scss"></style>
