<template>
  <div class="home-main">
    <div class="container">
      <h1 class="home-main-title">{{ $t('portalStatistics')  }} <br> {{ $t('portalStatistics2')  }} </h1>
      <v-form ref="form" @submit.prevent="getSearchData" class="home-main-search">
        <v-autocomplete
          :placeholder="$t('searchFromOfficialStatistics')"
          hide-details
          background-color="white"
          :items="data"
          outlined
          @keyup="searchBykeyup"
          @change="getSearchData"
          :loading="isLoading"
          v-model="searchInput"
          cache-items
          hide-no-data
        >
        <template slot="item" slot-scope="data">
         <div>
            <span class="icon icon-search"></span>
            <span class="ml-4 home-main-search-values">{{ data.item.text }}</span>
         </div>
        </template>
        </v-autocomplete>
        <button type="summbit">{{ $t('search') }}</button>
      </v-form>
      <div class="home-main-counter">
        <div>
          <span class="icon icon-chart"></span>
          <h4>{{ $t('totalIndicators') }}: {{ indicators }}</h4>
        </div>
        <div>
          <span class="icon icon-note"></span>
          <h4>{{ $t('totalSections') }}: {{ sdmxs }}</h4>
        </div>
        <div>
          <span class="icon icon-download"></span>
          <h4>{{ $t('download') }}: {{ downloads }}</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '@/api/Api';

export default {
  name: 'HomeMain',
  data() {
    return {
      data: [],
      searchInput: '',
      isLoading: false,
      downloads: 0,
      indicators: 0,
      notComplect: '',
      sdmxs: 0,
    };
  },
  methods: {
    async searchBykeyup(event) {
      const value = event.target ? event.target.value : event;
      this.notComplect = value;
      localStorage.setItem('search', value);
      this.isLoading = true;
      if (value.length >= 3) {
        const { data } = await Api.getOpenInformationData({ search: value });
        this.data = data.results.map((item) => ({
          text: item.name,
        }));
      }
      this.isLoading = false;
    },
    getSearchData() {
      if (this.notComplect.length >= 3) {
        this.$router.push({ path: '/search' });
      }
    },
    async getCounters() {
      const { data } = await Api.getCounters();
      this.downloads = data.downloads;
      this.indicators = data.indicators;
      this.sdmxs = data.sdmxs;
    },
  },
  mounted() {
    this.getCounters();
  },
};
</script>

<style src="./HomeSearch.scss" lang="scss"></style>
