<!-- eslint-disable max-len -->
<template>
  <div class="container home-sdmx">
    <div class="home-sdmx-head">
      <vueper-slides
        :visible-slides="5"
        :gap="3"
        :slide-ratio="1 / 4"
        :dragging-distance="200"
        :breakpoints="{ 800: { visibleSlides: 2, slideMultiple: 2 } }"
        class="no-shadow home-sdmx-slider"
        :bullets="false"
        :touchable="false"
      >
        <template #arrow-left>
          <div class="arrow arrow-left">
            <span class="icon icon-arrow"></span>
          </div>
        </template>

        <template #arrow-right>
          <div class="arrow arrow-right">
            <span class="icon icon-arrow"></span>
          </div>
        </template>
        <vueper-slide v-for="(item, i) in slides" :key="i" class="home-sdmx-slider-wrapper">
          <template #content>
            <div
              class="home-sdmx-slider__item"
              :class="{ active: item.active }"
              @click="sdmxChange(item.id)"
            >
              <div><span v-html="item.icon"></span></div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <h5
                    v-bind="attrs"
                    v-on="on"
                    v-for="i in item.name.split(' ').reverse()"
                    v-if="item.name.length < 21"
                  >
                    {{ i }}
                  </h5>
                  <h5
                    v-bind="attrs"
                    v-on="on"
                    v-if="item.name.length > 20"
                  >
                    {{ item.name }}
                  </h5>
                </template>
                <span>{{ item.name }}</span>
            </v-tooltip>
              <p>{{ item.count }}</p>
            </div>
          </template>
        </vueper-slide>
      </vueper-slides>
    </div>
    <div class="home-sdmx-childs">
      <ul>
        <li
          v-for="item in levelTwo"
          :key="item.id"
          @click="changeSdmxChildren(item.id)"
          class="home-level-two"
        >
          <div :class="{ active: item.active }">
            <span v-html="item.icon"></span>
            <h5>{{ item.name }}</h5>
          </div>
          <ol :class="{ open: item.active }">
            <li
              v-for="itemLevelThree in levelThree"
              :key="itemLevelThree.id"
              :class="{ active: itemLevelThree.active }"
              @click="changeLevelFour(itemLevelThree.id)"
              >
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 17.3C11.3 17.3 10.6 17.03 10.07 16.5L3.55002 9.98001C3.26002 9.69001 3.26002 9.21001 3.55002 8.92001C3.84002 8.63001 4.32002 8.63001 4.61002 8.92001L11.13 15.44C11.61 15.92 12.39 15.92 12.87 15.44L19.39 8.92001C19.68 8.63001 20.16 8.63001 20.45 8.92001C20.74 9.21001 20.74 9.69001 20.45 9.98001L13.93 16.5C13.4 17.03 12.7 17.3 12 17.3Z" fill="#292D32"/>
              </svg>

              <h5>{{ itemLevelThree.name }}</h5>
            </li>
          </ol>
        </li>
      </ul>
      <ul class="home-level-four">
        <li v-for="item in levelFour" :key="item.id">
          <router-link :to="`/reports-filed/${item.id}/meta-data`">
            <h5>{{ item.name }}</h5>
          </router-link>
        </li>
        <li class="home-level-four no-data-default" v-if="levelFour.length == 0">
          <h5>{{ $t('thereIsNoInformation') }}</h5>
        </li>
      </ul>
    </div>
    <div class="site-loader" v-if="siteLoading">
      <img src="@/assets/icons/Dark_logo.svg" alt="Site logo">
    </div>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';
import Api from '@/api/Api';

export default {
  name: 'HomeSDMX',
  components: {
    VueperSlides,
    VueperSlide,
  },
  data() {
    return {
      slides: [],
      siteLoading: true,
      defaultIcon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16 22.75H8C4.98 22.75 3.25 21.02 3.25 18V8.25C3.25 5.1 4.85 3.5 8 3.5C8.41 3.5 8.75 3.84 8.75 4.25C8.75 4.65 8.91 5.03 9.19 5.31C9.47 5.59 9.85 5.75 10.25 5.75H13.75C14.58 5.75 15.25 5.08 15.25 4.25C15.25 3.84 15.59 3.5 16 3.5C19.15 3.5 20.75 5.1 20.75 8.25V18C20.75 21.02 19.02 22.75 16 22.75ZM7.34998 5.02C5.76998 5.15 4.75 5.86 4.75 8.25V18C4.75 20.22 5.78 21.25 8 21.25H16C18.22 21.25 19.25 20.22 19.25 18V8.25C19.25 5.86 18.23 5.16 16.65 5.02C16.31 6.3 15.14 7.25 13.75 7.25H10.25C9.45 7.25 8.70001 6.94 8.13 6.37C7.75 5.99 7.48998 5.53 7.34998 5.02Z" fill="#0071BC"/><path d="M13.75 7.25H10.25C9.45 7.25 8.7 6.94 8.13 6.37C7.56 5.79999 7.25 5.05 7.25 4.25C7.25 2.6 8.6 1.25 10.25 1.25H13.75C14.55 1.25 15.3 1.56 15.87 2.13C16.44 2.7 16.75 3.45 16.75 4.25C16.75 5.9 15.4 7.25 13.75 7.25ZM10.25 2.75C9.42 2.75 8.75 3.42 8.75 4.25C8.75 4.65 8.91 5.03 9.19 5.31C9.47 5.59 9.85 5.75 10.25 5.75H13.75C14.58 5.75 15.25 5.08 15.25 4.25C15.25 3.85 15.09 3.47 14.81 3.19C14.53 2.91 14.15 2.75 13.75 2.75H10.25Z" fill="#0071BC"/><path d="M12 13.75H8C7.59 13.75 7.25 13.41 7.25 13C7.25 12.59 7.59 12.25 8 12.25H12C12.41 12.25 12.75 12.59 12.75 13C12.75 13.41 12.41 13.75 12 13.75Z" fill="#0071BC"/><path d="M16 17.75H8C7.59 17.75 7.25 17.41 7.25 17C7.25 16.59 7.59 16.25 8 16.25H16C16.41 16.25 16.75 16.59 16.75 17C16.75 17.41 16.41 17.75 16 17.75Z" fill="#0071BC"/></svg>',
      levelTwo: [],
      levelThree: [],
      levelFour: [],
      currentLang: localStorage.getItem('language') || 'uz',
    };
  },
  methods: {
    async getSDMXData() {
      const { data } = await Api.getOpenInformationDataWeb();
      this.slides = data.map((levelOne) => {
        const icon = levelOne.icon_svg != null ? levelOne.icon_svg : this.defaultIcon;
        const capitalizeFirstLetter = ([first, ...rest], locale = navigator.language) => first.toLocaleUpperCase(locale) + rest.join('');
        const response = {
          id: levelOne.id,
          name: capitalizeFirstLetter(levelOne[`name_${this.currentLang}`] ? levelOne[`name_${this.currentLang}`].toLowerCase() : levelOne.name.toLowerCase()),
          active: false,
          icon,
          count: 0,
          children: [],
        };
        if (levelOne.children) {
          response.children = levelOne.children.map((levelTwo) => {
            const iconTwo = levelTwo.icon_svg != null ? levelTwo.icon_svg : this.defaultIcon;
            const responseTwo = {
              id: levelTwo.id,
              name: levelTwo[`name_${this.currentLang}`] || levelTwo.name,
              active: false,
              icon: iconTwo,
              children: [],
            };
            if (levelTwo.children) {
              responseTwo.children = levelTwo.children.map((levelThree) => {
                const responseLavelThree = {
                  id: levelThree.id,
                  name: levelThree[`name_${this.currentLang}`] || levelThree.name,
                  children: [],
                  active: false,
                };
                if (levelThree.children) {
                  response.count += levelThree.children.length;
                  responseLavelThree.children = levelThree.children.map((levelFour) => ({
                    id: levelFour.id,
                    name: levelFour[`name_${this.currentLang}`] || levelFour.name,
                    active: false,
                  }));
                }
                return responseLavelThree;
              });
            }
            return responseTwo;
          });
        }
        return response;
      });
      this.levelTwo = this.slides[0].children;
      this.levelThree = this.slides[0].children[0].children;
      this.levelFour = this.slides[0].children[0].children[0].children;
      this.slides[0].active = true;
      this.levelTwo[0].active = true;
      this.levelThree[0].active = true;
      this.siteLoading = false;
    },
    changeLevelFour(id) {
      const clickSdmx = this.levelThree.find((item) => item.id === id);
      this.levelFour = clickSdmx.children;
      for (let i = 0; i < this.levelThree.length; i += 1) {
        this.levelThree[i].active = false;
      }
      clickSdmx.active = true;
    },
    sdmxChange(id) {
      const clickSdmx = this.slides.find((item) => item.id === id);
      this.levelTwo = clickSdmx.children;
      for (let i = 0; i < this.slides.length; i += 1) {
        this.slides[i].active = false;
      }
      this.changeSdmxChildren(this.levelTwo[0].id, 'selectFirst');
      clickSdmx.active = true;
    },
    changeSdmxChildren(id, setting) {
      const clickSdmx = this.levelTwo.find((item) => item.id === id);
      this.levelThree = clickSdmx.children;
      for (let i = 0; i < this.levelTwo.length; i += 1) {
        this.levelTwo[i].active = false;
      }
      if (setting === 'selectFirst') {
        if (clickSdmx.children.length === 0) {
          this.levelFour = [];
        } else {
          this.changeLevelFour(clickSdmx.children[0].id);
        }
      }
      clickSdmx.active = true;
    },
  },
  mounted() {
    this.getSDMXData();
  },
};
</script>

<style src="./HomeSDMX.scss" lang="scss"></style>
